<template>
  <article class="explain">
    <div class="content">
      <div class="title">
        <div class="label"></div>
        <h1>在线模考是什么？</h1>
      </div>
      <p class="pd-28 mb-40">在线模考是一种通过手机APP/电脑PC端进行线上模拟考试的新型考试方式。与传统机房考试相比，其突破了设备和场地的限制，操作更简便、智能。</p>
      <div class="title">
        <div class="label"></div>
        <h1>在线模考与普通作业有何区别？</h1>
      </div>
      <div class="pd-40">
        <h2>普通作业：</h2>
        <p class="pre-line">1. 不限制做题次数，学生可自由调节作答进度，便于学生日常的精读、精听。
          2. 学生练习完成，可立即查看成绩和答案。
          3. 主要用于学生在课后的日常性训练，反复纠正自己的易错点。
        </p>
        <h2>在线模考：</h2>
        <p class="mb-40 pre-line">1. 学生仅有一次答题机会，答题过程学生不能进行任何操作，与真实考试流程一致，更能体现学生的真实水平。
          2. 学生考完后是否公布、何时公布成绩和答案由教师自主设置，避免学生之间相互传阅。
          3. 在线模考广泛应用于周测、月考、期末考等阶段性考试，解决了学生没有机会使用语音室进行模考的难题。
        </p>
      </div>
      <div class="title">
        <div class="label"></div>
        <h1>如何布置在线模考？</h1>
      </div>
      <p class="pd-28" v-if="superAdmin === '2'">选择试题 → 去布置 → 设置考试以及答案公布时间 → 选择班级 → 编辑考试名称 → 确认布置。</p>
      <p class="pd-28" v-else>如需布置在线模考，请联系工作人员协助处理。联系电话：<span class="phone">400-996-0201。</span></p>
    </div>
    <footer class="footer" v-if="superAdmin === '2'">
      <el-checkbox v-model="hidden">以后不再提示此页面</el-checkbox>
      <el-button type="primary" @click="noticeChange">前往布置考试</el-button>
    </footer>
  </article>
</template>

<script>
export default {
  name: 'Explain',
  props: ['superAdmin'],
  data () {
    return {
      hidden: false
    }
  },
  methods: {
    noticeChange () {
      this.$emit('noticeChange', this.hidden)
    }
  }
}
</script>

<style scoped lang="scss">
  .explain {
    background-color: #fff;
    height: calc(100vh - 164px);
  }
  .content {
    height: calc(100vh - 248px);
    overflow: auto;
    padding: 10px 0 107px;
    @include font(17px, #666666);
    position: relative;
    z-index: 99;
    .title {
      @include flex(flex-start);
      height: 66px;
      .label {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
      h1 {
        color: #333333;
      }
    }
    h2 {
      color: #333333;
      font-weight: bold;
      margin: 10px 0;
    }
    .pd-28 {
      padding: 0 28px;
    }
    .pd-40 {
      padding: 0 40px;
    }
    .mb-40 {
      margin-bottom: 40px;
    }
    .phone {
      font-size: 22px;
      color: #309AF2;
    }
  }
  .footer {
    @include flex(flex-end);
    height: 84px;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
    font-size: 17px;
    position: relative;
    z-index: 100;
    .el-button {
      width: 250px;
      height: 46px;
      margin: 0 28px 0 48px;
      font-size: 17px;
    }
  }
</style>
